<template>
  <div class="bookings-modal-slide-wrapper">
    <ul class="clebex-item-section declaration-details" v-if="booking">
      <!-- Id -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="notes">
              ID
            </label>
            <p class="text-label">
              {{ booking.id }}
            </p>
          </div>
        </div>
      </li>
      <!-- Subject -->
      <li
        v-if="booking.subject"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="notes">
              {{ displayLabelName("bookings.bookings.subject") }}
            </label>
            <p class="text-label">
              {{ booking.subject }}
            </p>
          </div>
        </div>
      </li>
      <!-- Date from - date to -->
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <div class="clebex-item-range-cta">
                {{ displayDate(booking.datetime_from) }}
              </div>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-calendar" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <div class="clebex-item-range-cta">
                {{ displayDate(booking.datetime_to) }}
              </div>
            </li>
          </ul>
        </div>
      </li>
      <!-- Hour from - hour to -->
      <li class="clebex-item-section-item time">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <div class="clebex-item-range-cta">
                {{ displayTime(booking.datetime_from) }}
              </div>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <div class="clebex-item-range-cta">
                {{ displayTime(booking.datetime_to) }}
              </div>
            </li>
          </ul>
        </div>
      </li>
      <!-- Level -->
      <li
        v-if="booking.level"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="notes">
              {{ displayLabelName("bookings.bookings.level") }}
            </label>
            <p class="text-label">
              {{ booking.level }}
            </p>
          </div>
        </div>
      </li>
      <!-- Resource -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="notes">
              {{ displayLabelName("bookings.bookings.resource") }}
            </label>
            <p class="text-label">
              {{
                booking.resource.translations.find(el => el.locale === locale)
                  ? booking.resource.translations.find(
                      el => el.locale === locale
                    ).attributes.name
                  : booking.resource.name
              }}
            </p>
          </div>
        </div>
      </li>
      <!-- Host -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("bookings.bookings.host")
            }}</label>
            <p class="text-label">
              {{ booking.user.first_name }}
              {{ booking.user.last_name }}
            </p>
          </div>
        </div>
      </li>
      <!-- Status -->
      <li
        v-if="booking.status"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="notes">
              {{ displayLabelName("bookings.bookings.status") }}
            </label>
            <p class="text-label">
              {{ booking.status.name }}
            </p>
          </div>
        </div>
      </li>
      <!-- Status -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="notes">
              {{ displayLabelName("bookings.bookings.approval-status") }}
            </label>
            <p class="text-label">
              {{ booking.approval_status.name }}
            </p>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="approvedStatusId" class="clebex-item-section">
      <clebex-button
        :label="displayLabelName('bookings.bookings.approve')"
        @buttonPressed="
          declarationStatusAssign({
            declarationId: data,
            declarationApproveStatusId: approvedStatusId
          }).then(() => {
            this.getDeclarationInfo();
          })
        "
      >
      </clebex-button>
    </div>
    <div v-if="declinedStatusId" class="clebex-item-section">
      <clebex-button
        :label="displayLabelName('bookings.bookings.decline')"
        @buttonPressed="
          declarationStatusAssign({
            declarationId: data,
            declarationApproveStatusId: declinedStatusId
          }).then(() => {
            this.getDeclarationInfo();
          })
        "
      >
      </clebex-button>
    </div>
    <div v-if="showCheckInButton" class="clebex-item-section">
      <clebex-button
        :label="displayLabelName('bookings.bookings.check-in')"
        @buttonPressed="getDeclarationInfo(true)"
      >
      </clebex-button>
    </div>
  </div>
</template>

<script>
import ClebexButton from "@/components/global/Button";
import { mapGetters, mapState, mapActions } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import { getLang } from "@/services/http-service";

export default {
  name: "BookingSliderModalContent",
  components: {
    ClebexButton
  },
  created() {
    this.getDeclarationInfo();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("declaration", ["declarationApproveStatuses"]),
    ...mapState("bookings", ["bookingsPresets", "booking"]),
    locale() {
      return getLang();
    },
    approvedStatusId() {
      if (
        this.can("APPROVE") &&
        this.declarationApproveStatuses &&
        this.declarationApproveStatuses.length &&
        this.booking &&
        this.booking.approval_status.status === "PENDING"
      ) {
        const approvedId = this.declarationApproveStatuses.find(
          el => el.status === "APPROVED"
        ).id;
        return approvedId;
      }
      return null;
    },
    declinedStatusId() {
      if (
        this.can("APPROVE") &&
        this.declarationApproveStatuses &&
        this.declarationApproveStatuses.length &&
        this.booking &&
        ["PENDING", "APPROVED"].includes(this.booking.approval_status.status)
      ) {
        const declinedId = this.declarationApproveStatuses.find(
          el => el.status === "DECLINED"
        ).id;
        return declinedId;
      }
      return null;
    },
    showCheckInButton() {
      if (
        this.can("APPROVE") &&
        this.booking &&
        this.booking.status &&
        this.booking.status.status === "PRE_CHECK_IN" &&
        this.booking.approval_status &&
        this.booking.approval_status.status === "APPROVED"
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions("declaration", [
      "declarationStatusAssign",
      "declarationCheckIn"
    ]),
    ...mapActions("bookings", ["getBooking"]),
    getDeclarationInfo(checkIn) {
      this.getBooking({
        id: this.data,
        params: {
          includes: ["resource", "status", "approval_status", "user"]
        }
      }).then(() => {
        this.bookingsPresets.forEach(element => {
          element.resources.forEach(el => {
            let declaration = el.declarations.find(
              e => e.id === this.booking.id
            );
            if (declaration) {
              declaration.approval_status = this.booking.approval_status;
              declaration.status = this.booking.status;
            }
          });
        });
        if (
          checkIn &&
          this.booking.status &&
          this.booking.status.status === "PRE_CHECK_IN"
        ) {
          this.declarationCheckIn({
            id: this.booking.id,
            refresh: false
          }).then(() => {
            this.getDeclarationInfo();
          });
        }
      });
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  },
  props: {
    data: {
      type: Number,
      required: true
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.getDeclarationInfo();
        }
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("bookings/setBooking", null, {
      root: true
    });
  }
};
</script>
